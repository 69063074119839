@media (min-width: 800px) {
    #mainView {
        padding: 30px 100px;
    }
}

@media (min-width: 900px) {
    #loginBox {
        width: 50%;
    }
}

@media (max-width: 900px) {
    #loginBox {
        width: 90vw;
    }
}


.ui.inverted.segment {
    border-radius: 0px !important;
}



#loginErrorModal {
     height: 185px; 
    height: 20vh;
    top: 60vh;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ui.modal {
    max-height: 80vh;
    top: 5vh;
    left: 25vw;
    width: 50vw !important;
}

@media (max-width: 800px) {
    .ui.modal {
        top: 5vh;
        left: 10vw;
        width: 80vw !important;
    }
}






@media (max-width: 900px) {
    #tourRoundModal {
        width: 100vw !important;
        height: 100vh !important;
        max-height: 100vh;
        max-width: 100vw;
        top: 0;
        margin: 0;
        padding: 0;
    }
}