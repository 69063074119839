@media (max-width: 800px) {
    .games-dropdown.ui.fluid.dropdown {
        position: fixed;
        width: 100px;
    }
}

@media (min-width: 800px) {
    .games-dropdown.ui.fluid.dropdown {
        position: fixed;
        width: 250px;
    }
}