@media (min-width: 800px) {
    #mainView {
        padding: 30px 100px;
    }
}

@media (min-width: 900px) {
    #loginBox {
        width: 50%;
    }
}

@media (max-width: 900px) {
    #loginBox {
        width: 90vw;
    }
}


.ui.inverted.segment {
    border-radius: 0px !important;
}



#loginErrorModal {
     height: 185px; 
    height: 20vh;
    top: 60vh;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.ui.modal {
    max-height: 80vh;
    top: 5vh;
    left: 25vw;
    width: 50vw !important;
}

@media (max-width: 800px) {
    .ui.modal {
        top: 5vh;
        left: 10vw;
        width: 80vw !important;
    }
}






@media (max-width: 900px) {
    #tourRoundModal {
        width: 100vw !important;
        height: 100vh !important;
        max-height: 100vh;
        max-width: 100vw;
        top: 0;
        margin: 0;
        padding: 0;
    }
}
  .loader {
      border: 16px solid #ced4da;
      border-top: 16px solid #2185d0;
      border-radius: 50%;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite;
              animation: spin 2s linear infinite;
      margin: 0 auto;
      margin-top: 30vh;
  }

  @-webkit-keyframes spin {
      0% {
          -webkit-transform: rotate(0deg);
                  transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(360deg);
                  transform: rotate(360deg);
      }
  }

  @keyframes spin {
      0% {
          -webkit-transform: rotate(0deg);
                  transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(360deg);
                  transform: rotate(360deg);
      }
  }

  .errorMessage {
    color: red;
    font-size: 0.75em;
    display: relative;
  }
  

.errorMessage {
  color: red;
  display: relative;
}
.ag-row-hover {
  /* putting in !important so it overrides the theme's styling as it hovers the row also */
  background-color: #dfdfff !important;
}


@media only screen and (orientation:portrait){
  #graphcontainer {  
    height: 100vw;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
@media only screen and (orientation:landscape){
  #graphcontainer {  
     -webkit-transform: rotate(0deg);
     transform: rotate(0deg);
  }
}

@media (max-width: 900px) {
    #tourRoundModal {
        width: 100vw !important;
        height: 100vh !important;
        max-height: 100vh;
        max-width: 100vw;
        top: 0;
        margin: 0;
        padding: 0;
    }
}

.wrapped{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
@media (max-width: 800px) {
    .games-dropdown.ui.fluid.dropdown {
        position: fixed;
        width: 100px;
    }
}

@media (min-width: 800px) {
    .games-dropdown.ui.fluid.dropdown {
        position: fixed;
        width: 250px;
    }
}
