
@media (max-width: 900px) {
    #tourRoundModal {
        width: 100vw !important;
        height: 100vh !important;
        max-height: 100vh;
        max-width: 100vw;
        top: 0;
        margin: 0;
        padding: 0;
    }
}
